<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="学员搜索" class="searchboxItem ci-full">
              <span class="itemLabel">学员搜索:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div
              title="班级搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">班级搜索:</span>
              <el-select
                v-model="projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superProjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in projectList"
                  :key="index"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in CompanyList"
                  :key="index"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="学时状态" class="searchboxItem ci-full el-selectBox">
              <span class="itemLabel">学时状态:</span>
              <el-select
                v-model="completeState"
                placeholder="请选择"
                clearable
                size="small"
                class="smallselect"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in complete"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            
          </div>
          <div class="searchbox" style="margin-top: 10px">
            <div title="所属企业" class="searchboxItem ci-full">
              <span class="itemLabel">所属企业:</span>
              <el-select
                size="small"
                v-model="userCompId"
                remote
                :remote-method="getuserCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in userCompanyList"
                  :key="index"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="是否评价" class="searchboxItem ci-full">
              <span class="itemLabel">是否评价:</span>
              <el-select
                v-model="evaluate"
                placeholder="请选择"
                clearable
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in evaluateList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="是否结业" class="searchboxItem ci-full">
              <span class="itemLabel">是否结业:</span>
              <el-select
                v-model="graduationState"
                placeholder="请选择"
                clearable
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in graduationStateList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top: 10px">
            <div title="工作单位" class="searchboxItem ci-full">
              <span class="itemLabel">工作单位:</span>
              <el-input
                v-model="userWorkUnit"
                type="text"
                size="small"
                placeholder="请输入工作单位"
                clearable
              />
            </div>
            <div title="通过考试" class="searchboxItem ci-full">
              <span class="itemLabel">通过考试:</span>
              <el-select
                v-model="paperIsComplete"
                placeholder="请选择"
                clearable
                size="small"
                class="smallselect"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in passExamList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                clearable
                filterable
                v-model="areaId"
                :options="areaTreeList"
                :props="propsArea"
                size="small"
              ></el-cascader>
            </div>
            <div title="认证状态" class="searchboxItem ci-full">
              <span class="itemLabel">认证状态:</span>
              <el-select
                v-model="authentication"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in authenticationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top: 10px">
            <div title="所属区域" class="searchboxItem ci-full el-selectBox">
              <span class="itemLabel">所属区域:</span>
              <el-cascader
                clearable
                filterable
                v-model="userClassAreaId"
                :options="areaTreeList"
                :props="propsArea"
                size="small"
              ></el-cascader>
            </div>
            <div title="入班时间" class="searchboxItem ci-full">
              <span class="itemLabel">入班时间:</span>
              <el-date-picker
                v-model="date"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div title="结业时间" class="searchboxItem ci-full">
              <span class="itemLabel">结业时间:</span>
              <el-date-picker
                v-model="graduationTime"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <el-button
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="exportData()"
              >导出</el-button
            >
          </div>
        </div>
        <div style="margin: 5px 15px 0px">
          <span>培训要求总学时：{{ requireLessonSum }}</span>
          <span style="margin: 0 15px"
            >培训完成总学时：{{ studyLessonSum }}</span
          >
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                fixed
                width="160"
              />
              <el-table-column
                label="性别"
                align="center"
                show-overflow-tooltip
                width="60px"
                prop="sex"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.sex == "1"
                      ? "男"
                      : scope.row.sex == "2"
                      ? "女"
                      : ""
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="联系电话"
                width="100"
                align="left"
                show-overflow-tooltip
                prop="mobile"
              />
              <el-table-column
                label="学员类型"
                width="230"
                align="left"
                show-overflow-tooltip
                prop="projectUserType"
              />
              <el-table-column
                label="机构名称"
                width="200"
                align="left"
                show-overflow-tooltip
                prop="compName"
              />
              <el-table-column
                label="认证状态"
                align="left"
                show-overflow-tooltip
                prop="authentication"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="200"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                width="200"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                width="200"
              />
              <el-table-column
                label="课件总数"
                align="right"
                show-overflow-tooltip
                prop="kpointTotalNum"
              />
              <el-table-column
                label="已完成课件数"
                align="right"
                show-overflow-tooltip
                prop="completeKpointNum"
              />
              <el-table-column
                label="是否评价"
                align="center"
                show-overflow-tooltip
                prop="commentState"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.commentState == false ? "否" : "是"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="是否考试通过"
                align="center"
                show-overflow-tooltip
                width="100px"
                prop="paperState"
              />
              <el-table-column
                label="考试次数"
                align="right"
                show-overflow-tooltip
                width="80px"
                prop="paperNum"
              ></el-table-column>
              <el-table-column
                label="学习状态"
                align="center"
                width="100px"
                prop="completeState"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.completeState != '40'">{{
                    $setDictionary("LEARNINGSTATE", scope.row.completeState)
                  }}</span>
                  <el-tooltip
                    v-else
                    class="item"
                    effect="dark"
                    :content="scope.row.notCompleteReasons || 0"
                    placement="top"
                  >
                    <span
                      style="
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                      "
                      >{{
                        $setDictionary("LEARNINGSTATE", scope.row.completeState)
                      }}</span
                    >
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                label="是否结业"
                align="center"
                show-overflow-tooltip
                width="100px"
              >
                <template slot-scope="scope">{{
                  scope.row.graduationState ? "是" : "否"
                }}</template>
              </el-table-column>
              <el-table-column
                label="结业时间"
                align="center"
                show-overflow-tooltip
                width="150px"
              >
                <template slot-scope="scope">{{
                  scope.row.graduationTime | moment
                }}</template>
              </el-table-column>
              <el-table-column
                label="累计在线学习时长"
                align="right"
                show-overflow-tooltip
                prop="totalTime"
                width="150"
              />
              <el-table-column
                label="学习进度(%)"
                align="right"
                show-overflow-tooltip
                prop="percentage"
                width="150"
              />
              <el-table-column
                label="最后一次学习时间"
                align="left"
                show-overflow-tooltip
                prop="lastTime"
                width="150"
              />
              <el-table-column
                label="所属企业"
                align="left"
                show-overflow-tooltip
                prop="userCompName"
                width="200"
              />
              <el-table-column
                label="工作单位"
                align="left"
                show-overflow-tooltip
                prop="userWorkUnit"
                width="200"
              />

              <el-table-column
                label="所属区域"
                align="left"
                show-overflow-tooltip
                width="250"
                prop="userClassAreaName"
              />
              <el-table-column
                label="工种"
                align="left"
                show-overflow-tooltip
                width="150"
                prop="workType"
              />
              <el-table-column
                label="入班时间"
                align="left"
                show-overflow-tooltip
                width="150px"
              >
                <template slot-scope="scope">{{
                  scope.row.createTime | moment
                }}</template>
              </el-table-column>

              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="280px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="
                      handleDetail(scope.row.userId, scope.row.projectCourseId)
                    "
                    >学习详情</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleTrajectory(scope.row)"
                    >学习轨迹</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="viewLog(scope.row)"
                    >查看日志</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="viewLogZT(scope.row)"
                    >查看暂停日志</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { Message } from "element-ui";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/studentStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      exportName: "projectName", // 导出名称
      userId: "", //学员Id
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectId: "", //班级Id
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      courseName: "", //课程名称
      compId: "", //机构Id
      userCompId: "", //所属企业Id
      userWorkUnit: "", //工作单位
      completeState: '', //学时状态
      evaluate: "", //是否评价
      paperIsComplete: "", //是否通过考试
      graduationState: "", //是否结业
      date: [], //入班时间
      areaId: "", //行政区划
      userClassAreaId:"", // 所属区域
      authentication: "", //认证状态
      requireLessonSum: 0, //培训要求总学时
      studyLessonSum: 0, //培训完成总学时
      //机构名称下拉数据
      CompanyList: [],
      //所属企业下拉数据
      userCompanyList: [],
      complete: [],
      //是否评价下拉数据
      evaluateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      //是否结业下拉数据
      graduationStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      //是否通过考试下拉数据
      passExamList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "10",
          label: "是",
        },
        {
          value: "20",
          label: "否",
        },
        {
          value: "30",
          label: "无配置",
        },
      ],
      graduationTime: "",
      areaTreeList: [], //行政区划
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      authenticationList: [], //认证状态
    };
  },
  //页面初始化时不自动查询列表数据
  doNotInit: true,
  created() {
    this.getAreaTree();
    this.getTableHeight();
    this.getCompleteList();
    this.superUserSelect();
    this.superProjectSelect();
  },

  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  watch: {
    projectId: function () {
      this.superUserSelect();
    },
    userId: function () {
      this.superProjectSelect();
    },
  },
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    getSearchParams() {
      let params = {
        courseName: this.courseName || "",
        projectId: this.projectId || "",
        userId: this.userId || "",
        compNameId: this.compId || "",
        userCompId: this.userCompId || "",
        complateState: this.completeState || "",
        commentState: this.evaluate,
        paperIsComplete: this.paperIsComplete,
        graduationState: this.graduationState,
        userWorkUnit: this.userWorkUnit,
        areaId: this.areaId,
        authentication: this.authentication,
        userClassAreaId:this.userClassAreaId //所属区域
      };
      if (this.date) {
        params.createTimeBeginDate = this.date[0];
        params.createTimeEndDate = this.date[1];
      }
      if (this.graduationTime) {
        params.graduationTimeStart = this.graduationTime[0];
        params.graduationTimeEnd = this.graduationTime[1];
      }
      return params;
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    exportData() {
      if (this.projectId == "" && this.userId == "" && this.compId == "") {
        Message.error({
          message: "班级、学员、机构必须选择一项",
          duration: "2000",
        });
        return;
      }
      this.$post("/biz/report/user/pageYw-export", this.getSearchParams())
        .then((res) => {
          // if (res.status == 0) {
          //   window.open(res.data);
          // } else {
          //   this.$message({
          //     type: "error",
          //     message: res.message
          //   });
          // }
          if (res.status == "0") {
            let list = res.data;
            this.editPopupDialogVisible = false;
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          return;
        });
    },
    getCompleteList() {
      const studyList = this.$setDictionary("LEARNINGSTATE", "list");
      const authenticationList = this.$setDictionary("AUTHENTICATION", "list");
      for (const key in studyList) {
        if (key) {
          this.complete.push({
            value: key,
            label: studyList[key],
          });
        }
      }
      for (const key in authenticationList) {
        this.authenticationList.push({
          value: key,
          label: authenticationList[key],
        });
      }
    },
    getData(pageNum = 1) {
      if (this.projectId == "" && this.userId == "" && this.compId == "") {
        Message.error({
          message: "班级、学员、机构必须选择一项",
          duration: "2000",
        });
        return;
      }
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      let par = this.getSearchParams();
      params = {
        ...params,
        ...par,
      };
      this.doFetch({
        url: "/biz/report/user/page-list",
        params,
        pageNum,
      });
      this.getListInfo({
        url: "/biz/report/user/userClassHoursSum",
        params: par,
      });
    },
    getListInfo(params) {
      this.$post(params.url, params.params).then((res) => {
        if (res.status == "0") {
          const data = res.data || {};
          this.requireLessonSum = data.requireLessonSum;
          this.studyLessonSum = data.studyLessonSum;
        }
      });
    },
    /* 学习轨迹 */
    handleTrajectory(row) {
      let rowJson = {
        userId: row.userId,
        projectCourseId: row.projectCourseId,
        projectName: row.projectName,
        courseName: row.courseName,
        userName: row.userName,
        idcard: row.idcard,
        mobile: row.mobile,
      };
      let data = JSON.stringify(rowJson);
      this.$router.push({
        path: "/web/set/TrajectoryList",
        query: {
          data,
          userId: row.userId,
          projectCourseId: row.projectCourseId,
        },
      });
    },
    //查看日志
    viewLog(row) {
      let rowJson = {
        userId: row.userId,
        projectCourseId: row.projectCourseId,
        projectName: row.projectName,
        courseName: row.courseName,
        userName: row.userName,
        idcard: row.idcard,
        mobile: row.mobile,
        projectId: row.projectId,
      };
      let data = JSON.stringify(rowJson);
      this.$router.push({
        path: "/web/operate/studentStatisticsJournal",
        query: {
          data,
          userId: row.userId,
          projectCourseId: row.projectCourseId,
          projectId: row.projectId,
        },
      });
    },
    viewLogZT(row) {
      let rowJson = {
        userId: row.userId,
        projectCourseId: row.projectCourseId,
        projectName: row.projectName,
        courseName: row.courseName,
        userName: row.userName,
        idcard: row.idcard,
        mobile: row.mobile,
        projectId: row.projectId,
      };
      let data = JSON.stringify(rowJson);
      ;
      this.$router.push({
        path: "/web/operate/studentStatisticsJournalZT",
        query: {
          data,
          userId: row.userId,
          projectCourseId: row.projectCourseId,
          pageTitle: "查看暂停日志",
        },
      });
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /* 所属企业 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    getTime(val) {
      if (!val) {
        return "";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return h + "时" + m + "分" + s + "秒" || "";
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 9) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    handleDetail(userId, projectCourseId) {
      this.$router.push({
        path: "/web/operate/learningDetails",
        query: { userId, projectCourseId },
      });
    },
    //获取行政区划
    getAreaTree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areaTreeList = ret.data;
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.operationStudent {
  .itemLabel {
    min-width: 5rem;
  }
  .ci-full {
    .el-input--suffix .el-input__inner {
      padding-right: 3px;
    }
    .el-input {
      input {
        width: 100% !important;
      }
    }
    .smallselect {
      input {
        width: 100% !important;
      }
    }
  }
}
.el-select__tags-text {
  min-width: 2rem !important;
}
.el-selectBox {
  .el-select {
    // min-width: 15rem;
    .el-tag {
      min-width: 10px !important;
    }
  }
}
</style>
